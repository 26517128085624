.row-cols-1 {
  .col {
    width: 25%;
    @include respond-below(custom1199) {
      width: 50%;
    }
    @include respond-below(custom767) {
      width: 100%;
    }
  }
}
// (1px-30px are linear, above 30px are step increment of 1)
.fs-1 {
  font-size: 0.0625rem; //1px
}
.fs-2 {
  font-size: 0.125rem; //2px
}
.fs-3 {
  font-size: 0.1875rem; //3px
}
.fs-4 {
  font-size: 0.25rem; //4px
}
.fs-5 {
  font-size: 0.3125rem; //5px
}
.fs-6 {
  font-size: 0.375rem; //6px
}
.fs-7 {
  font-size: 0.4375rem; //7px
}
.fs-8 {
  font-size: 0.5rem; //8px
}
.fs-9 {
  font-size: 0.5625rem; //9px
}
.fs-10 {
  font-size: 0.625rem; //10px
}
.fs-11 {
  font-size: 0.6875rem; //11px
}
.fs-12 {
  font-size: 0.75rem; //12px
}
.fs-13 {
  font-size: 0.8125rem; //13px
}
.fs-14 {
  font-size: 0.875rem; //14px
}
.fs-15 {
  font-size: 0.9375rem; //15px
}
.fs-16 {
  font-size: 1rem; //16px
}
.fs-17 {
  font-size: 1.0625rem; //17px
}
.fs-18 {
  font-size: 1.125rem; //18px
}
.fs-19 {
  font-size: 1.1875rem; //19px
}
.fs-20 {
  font-size: 1.25rem; //20px
}
.fs-21 {
  font-size: 1.3125rem; //21px
}
.fs-22 {
  font-size: 1.375rem; //22px
}
.fs-23 {
  font-size: 1.4375rem; //23px
}
.fs-24 {
  font-size: 1.5rem; //24px
}
.fs-25 {
  font-size: 1.5625rem; //25px
}
.fs-26 {
  font-size: 1.625rem; //26px
}
.fs-27 {
  font-size: 1.6875rem; //27px
}
.fs-28 {
  font-size: 1.75rem; //28px
}
.fs-29 {
  font-size: 1.8125rem; //29px
}
.fs-30 {
  font-size: 1.875rem; //30px
}
.fs-32 {
  font-size: 2rem; //32px
}
.fs-34 {
  font-size: 2.125rem; //34px
}
.fs-36 {
  font-size: 2.25rem; //36px
}
.fs-38 {
  font-size: 2.375rem; //38px
}
.fs-40 {
  font-size: 2.5rem; //40px
}
.card {
  &.card-bg-primary {
    background: $primary;
    color: $white;
    border-color: $primary;
  }
  &.card-bg-secondary {
    background: $secondary;
    color: $white;
    border-color: $secondary;
  }
  &.card-bg-warning {
    background: $warning;
    color: $white;
    border-color: $warning;
  }
  &.card-bg-info {
    background: $info;
    color: $white;
    border-color: $info;
  }
  &.card-bg-success {
    background: $success;
    color: $white;
    border-color: $success;
  }
  &.card-bg-danger {
    background: $danger;
    color: $white;
    border-color: $danger;
  }
  &.card-bg-light {
    background: #f8f9fa;
    color: #5b6670;
    border-color: #f8f9fa;
  }
  &.card-bg-dark {
    background: $black;
    color: $white;
    border-color: $black;
  }
}
.card.card-fullscreen {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-start: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
}
.card .collapse-close {
  display: none;
}
.card.card .collapsed .collapse-close {
  display: block;
}
.card.card .collapsed .collapse-open {
  display: none;
}
.bg-primary-transparent {
  background: rgba($primary, 0.5);
  color: $primary;
  font-weight: 400;
}
.bg-secondary-transparent {
  background: rgba($secondary, 0.5);
  color: $secondary;
  font-weight: 400;
}
.bg-info-transparent {
  background: rgba($info, 0.5);
  color: $info;
  font-weight: 400;
}
.bg-danger-transparent {
  background: rgba($danger, 0.5);
  color: $danger;
  font-weight: 400;
}
.bg-success-transparent {
  background: rgba($success, 0.5);
  color: $success;
  font-weight: 400;
}
.bg-warning-transparent {
  background: rgba($warning, 0.5);
  color: $warning;
  font-weight: 400;
}
.card .card-text {
  font-size: 0.813rem;
}
.card {
  border: 0;
  margin-bottom: 24px;
  box-shadow: $box-shadow-lg;
  .leave-inline-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    min-height: 44px;
    .input-group-text {
      background-color: $body-bg;
      border-color: $light-900;
    }
    @include respond-below(custom575) {
      display: block;
    }
  }
  .card-title {
    color: $secondary;
    font-size: $font-size-20;
    font-weight: $font-weight-medium;
    margin-bottom: 20px;
  }
  .card-header {
    padding: 20px;
    border-bottom: 0;
    background: transparent;
    .card-title {
      color: $secondary;
      font-size: $font-size-20;
      font-weight: $font-weight-medium;
      margin-bottom: 0;
      &.with-switch {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-flex-pack: space-between;
      }
    }
  }
  h6 {
    &.counter {
      font-size: 16px;
    }
    &.card-text {
      font-size: 14px;
    }
  }
  .card-body {
    padding: 24px;
  }
}
.card-group {
  .card {
    margin-bottom: 1.5rem;
  }
}
.card {
  &.custom-card {
    border-radius: 5px;
    border: 0;
    background-color: $white;
    position: relative;
    margin-block-end: 1.5rem;
    width: 100%;
    .card-header {
      padding: 1rem 1.25rem;
      background-color: transparent !important;
      border-block-end: 1px solid #dbe0e6;
      display: flex;
      align-items: center;
      position: relative;
      flex-wrap: wrap;
      gap: 0.25rem;
      &:first-child {
        border-radius: 0.625rem 0.625rem 0 0;
      }
      .card-title {
        position: relative;
        margin-block-end: 0;
        font-size: 0.9375rem;
        font-weight: $font-weight-bold;
        text-transform: capitalize;
        .subtitle {
          margin-block-end: 0;
          text-transform: initial;
        }
      }
    }
    .card-footer {
      background-color: transparent !important;
      border-block-start: 1px solid #dbe0e6;
      padding: 1rem 1.5rem;
      font-size: $font-size-16;
    }
    .card-body {
      padding: 1.5rem;
      color: $secondary-600;
    }
    .card-link {
      font-size: 0.75rem;
    }
  }
  .card-text {
    font-size: 0.813rem;
  }
  .footer-card-icon {
    width: 60px;
    height: 60px;
  }
  &.overlay-card {
    position: relative;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.9);
    .card-header {
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
    }
    .card-footer {
      border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }
    .over-content-bottom {
      top: auto;
    }
    &:before {
      content: "";
      inset-inline-start: 0;
      inset-inline-end: 0;
      inset-block-start: 0;
      inset-block-end: 0;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
    }
  }
  &.card-bg-primary {
    background-color: $primary;
    color: $white;
    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
      &:before {
        background-color: $white;
      }
    }
    .card-body {
      color: $white;
    }
    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  &.card-bg-secondary {
    background-color: $secondary;
    color: $white;
    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
      &:before {
        background-color: $white;
      }
    }
    .card-body {
      color: $white;
    }
    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  &.card-bg-warning {
    background-color: $warning;
    color: $white;
    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
      &:before {
        background-color: $white;
      }
    }
    .card-body {
      color: $white;
    }
    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  &.card-bg-info {
    background-color: $info;
    color: $white;
    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
      &:before {
        background-color: $white;
      }
    }
    .card-body {
      color: $white;
    }
    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  &.card-bg-success {
    background-color: $success;
    color: $white;
    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
      &:before {
        background-color: $white;
      }
    }
    .card-body {
      color: $white;
    }
    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  &.card-bg-danger {
    background-color: $danger;
    color: $white;
    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
      &:before {
        background-color: $white;
      }
    }
    .card-body {
      color: $white;
    }
    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  &.card-bg-light {
    background-color: $secondary-100;
    color: $secondary-600;
    .card-header {
      background-color: rgba(0, 0, 0, 0.05) !important;
      color: $secondary-600;
      border-block-end: $secondary-500;
    }
    .card-body {
      color: $secondary-600;
    }
    .card-footer {
      background-color: rgba(0, 0, 0, 0.05) !important;
      color: $secondary-600;
      border-block-start: $secondary-500;
    }
  }
  &.card-bg-dark {
    background-color: $secondary-800;
    color: $white;
    .card-header {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-end: 1px solid rgba(255, 255, 255, 0.1);
      &:before {
        background-color: $white;
      }
    }
    .card-body {
      color: $white;
    }
    .card-footer {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      border-block-start: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  .card-anchor {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
  }
  .collapse-close {
    display: none;
  }
  &.card .collapsed {
    .collapse-close {
      display: block;
    }
    .collapse-open {
      display: none;
    }
  }
  &.card-fullscreen {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    inset-inline-start: 0;
    bottom: 0;
    z-index: 9999;
    margin: 0;
  }
}
.cals-icon {
  color: $secondary-800;
}
.callout {
  padding: 1.25rem;
  margin-block-start: 1.25rem;
  margin-block-end: 1.25rem;
  background-color: $secondary-100;
  border-inline-start: 0.25rem solid $secondary-500;
}
.callout-info {
  background-color: rgba($info, 0.075);
  border-color: rgba($info, 0.5);
}
.callout-warning {
  background-color: rgba($warning, 0.075);
  border-color: rgba($warning, 0.5);
}
.callout-danger {
  background-color: rgba($danger, 0.075);
  border-color: rgba($danger, 0.5);
}
.btn-facebook {
  background-color: $info;
  color: $white;
  border: 1px solid $info;
  &:hover,
  &:focus,
  &:active {
    background-color: $info !important;
    color: $white !important;
    border: 1px solid $info !important;
  }
}
.btn-instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  color: $white;
  border: 1px solid transparent;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    border: 1px solid transparent;
  }
}
.btn-twitter {
  background-color: #00acee;
  color: $white;
  border: 1px solid #00acee;
  &:hover,
  &:focus,
  &:active {
    background-color: #00acee !important;
    color: $white !important;
    border: 1px solid #00acee !important;
  }
}
.btn-icon {
  width: 2.313rem;
  height: 2.313rem;
  font-size: 0.95rem;
  flex-shrink: 0;
}
.btn-wave {
  display: inline-block !important;
}

@media (max-width: 575px) {
  .overlay-card {
    .card-text {
      font-size: 0.613rem !important;
    }
  }
}
@media (max-width: 400px) {
  .overlay-card {
    .card-text {
      font-size: 0.413rem !important;
    }
  }
}
.lanc {
  display: flex;
  flex-wrap: wrap;
  .card {
    flex: 1 1;
    flex-basis: calc(20% - 10px);
    margin: 5px;
  }
  
  @media (min-width: 576px) and (max-width: 991px) {
    .card {
      flex-basis: calc(50% - 10px);
    }
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
    .card {
      flex: auto;
    }
  }
}
